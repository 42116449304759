.leaflet-map-container .leaflet-overlay-pane svg > text > textPath {
    font-size: 21px;
}

.vz-icon-style {
    font-size: 14px;
    color: blue;
    width: 100px;
}

svg path {
    z-index: 10000;
}


svg g path {
    z-index: 1001 !important;
}

.leaflet-overlay-pane {
    z-index: auto;
}

.leaflet-container .leaflet-overlay-pane svg {
    z-index: 999999;
}
.leaflet-marker-draggable {
    margin-left: -7px !important;;
    margin-top: -7px !important;;
    width: 14px !important;;
    height: 14px !important;;
}

