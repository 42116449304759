@import "custom";
$open_sans: 'Roboto', 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;

.dialog {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  &-faq {
    width: 700px;
    margin-left: 40px;
    background: white;
    border: 2px solid #5fa2dd;
    &_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      background: #5fa2dd;
      &--titlePage {
        color: #fff;
        font-size: 14px;
        font-family: $open_sans;
        font-weight: $mainfw;
        padding: 8px;
        :first-child {
          padding-right: 5px;
          width: 30px;
        }
        :last-child {
          margin-left: 10px;
          color: #fff;
        }
      }
      &--closeButton {
        color: #fff;
        margin-right: 10px;
      }
    }
    &_caption {
      padding: 10px;
      font-family: $open_sans;
      font-weight: $mainfw;
    }
    &_footer {
      height: 50px;
      top: 360px;
      padding: 5px;
      width: 696px;
      border-top: 1px solid #5fa2dd;
      display: flex;
      justify-content: end;
      &--nextButton {
        padding: 10px;
        margin-right: 5px;
        background: lightgray;
        font-size: 14px;
        font-family: $open_sans;
        font-weight: $mainfw;
      }
    }
  }
}
