.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.btn-warning {
    color: #212529;
    border-color: #5fa2dd;
    background-color: #5fa2dd;
    box-shadow: none;
    border-radius: 0px;
    margin-left: 0px;
    width: 80px;
}

.btn {
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    padding: 0.5625rem 1rem;
    font-size: 0.75rem;
    line-height: 1.125;
    border-radius: 0.25rem;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.float-right {
    float: right !important;
}

.btn-secondary {
    color: #fff;
    border-color: #5A6169;
    background-color: #5A6169;
    box-shadow: none;
    border-radius: 0px;
    margin-left: 0px;
    width: 80px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.row.player {
    margin-left: 0px!important;
}
.col-12 {
    flex: 0 0 100%;
    max-width: 1200px;
    margin-left: 0;
}