@charset "utf-8";

@import '../../node_modules/foundation-sites/scss/foundation';
@import '../../node_modules/motion-ui/motion-ui';
@import 'fonts';

@include foundation-everything;

@import 'settings';
@import 'custom';
@import 'zones';
@import 'faq';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
//@include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;

// Generic components
//@include foundation-button;
//@include foundation-button-group;
//@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;
// Menu-based containers
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
//@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
//@include motion-ui-transitions;
//@include motion-ui-animations;

//$blue: #60A2DE;
//$dblue: #5895CE;
$blue: #5fa2dd;
$dblue: #5897ce;
$btn-blue: #5795cb;
$grey: #919191;
$llgrey: #e9e9e9;
$lgrey: #d0d0d0;
$dgrey: #404040;
$header-grey: #f8f8f8;

$light-blue-grey: #eaeff4;
$light-yellow: #ffefbb;

$text-grey: #606060;

$images-grey: #cccccc;
$images-bgrey: #f6f6f6;
$images-hover: #777;

$animation: .3s all ease;

$open_sans: 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;

@import 'images';

a, button, select, option {
  cursor: pointer;
}

select {
  font-size: 13px;
}

header {
  background-color: $blue;

  p {
    color: white;
    font-size: 13px;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      max-width: 63px;
      margin-right: 26px;
    }
  }

  .user {
    a {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      img {
        max-width: 17px;
        margin-right: 7px;
      }

      select {
        margin-bottom: 0;
        height: 23px;
        border: 0;
        font-size: 13px;
        max-width: 200px;
      }
    }
  }

  .roles {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 17px;
      margin-right: 7px;
    }

    select {
      margin-bottom: 0;
      height: 30px;
      border: 0;
      font-size: 13px;
      max-width: 200px;
    }

    @media (max-width: 1366px) {
      //margin-left: 60px;
    }
  }

  .lern {
    padding-left: 10px;
    display: flex;

    a {
      display: flex;
      align-items: center;
      margin-right: 30px;
      transition: all .3s ease;
      padding: 7px 10px;
      background-color: $blue;

      &:hover {
        background-color: $dblue;
      }

      img {
        max-width: 17px;
        height: auto;
        margin-right: 7px;
      }

      @media (max-width: 1860px) {
        margin-right: 10px;
      }
      @media (max-width: 1600px) {
        margin-right: 0px;
      }
    }
  }

}

.content {
  .urls-wrapper {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    background-color: $blue;
    white-space: nowrap;
    overflow: auto;

    p {
      color: white;
      font-size: 13px;
    }

    a {
      display: inline-flex;
      align-items: center;
      padding: 7px 10px;
      transition: all .3s ease;
      position: relative;
      margin-right: 5px;

      &.active {
        background-color: white;

        p {
          color: $dblue
        }

        &:hover {
          background-color: white;
        }

        &:focus {
          outline: 1px solid $blue;
          outline-offset: -3px;
        }

        img, svg {
          color: #4c82b1;
        }
      }

      &:hover {
        background-color: $dblue;
      }

      img, svg {
        max-width: 17px;
        margin-right: 7px;
        color: #f0f0f0;
      }

      // &:before {
      //   width: 17px;
      //   height: 17px;
      //   display: block;
      //   position: absolute;
      //   top: 9px;
      //   left: 10px;
      //   content: '';
      //   background-size: contain;
      //
      // }
      // &.camera {
      //   &:before {
      //     background-image: url('/assets/img/camera.png');
      //   }
      //   &.active {
      //
      //   }
      // }
    }

    &.modal-urls {
      a {
        padding-left: 37px;
        position: relative;

        &:before {
          pointer-events: none;
          position: absolute;
          content: "\f02d";
          display: block;
          top: 4px;
          left: 12px;
          color: #f0f0f0;
          font-family: FontAwesome;
          font-size: 20px;
          @media (max-width:1410px) {
            font-size: 16px;
            left: 13px;
          }
        }
      }

      a.active {
        &:before {
          color: #4c82b1;
        }
      }
    }
  }
  @media(max-width: 1410px){
    .urls-wrapper {
      p {
        font-size: 10px;
      }
      a {
        padding: 7px 4px;
        margin-right: 1px;
        svg {
          max-width: 13px;
        }
      }
    }
  }
}

.content-body {
  min-height: calc(100vh - 140px);

  .cameras-list {
    padding: 30px 12px 30px 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item {
      width: 209px;
      height: 123px;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: 50% 50%;
      }

      .on-off {
        color: black;
        position: absolute;
        top: 7px;
        right: 10px;

        p {
          line-height: 1;
          border-radius: 25px;
          font-weight: 600;
          padding: 3px 9px 5px 9px;
          font-size: 13px;
        }

        .on {
          background-color: #60DE74;
        }

        .off {
          background-color: #DE6060;
        }
      }

      .lable {
        background-color: black;
        color: white;
        position: absolute;
        left: 6px;
        bottom: 3px;
        padding: 5px 5px;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }

  .camera_item {
    padding: 25px 15px 30px;

    .title {
      p {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .tabs_list {
      overflow-x: hidden;

      .tabs_list-href {
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: flex-start;

        a {
          padding: 8px 15px;
          transition: all .3s ease;
          position: relative;
          font-size: 14px;
          color: #545252;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;

          &.active {
            border: 1px solid #b9b9b9;
            border-bottom: 0;

            &:before {
              position: absolute;
              bottom: -1px;
              right: 100%;
              height: 1px;
              width: 100vw;
              background-color: #b9b9b9;
              content: '';
              display: block;
            }

            &:after {
              position: absolute;
              bottom: -1px;
              left: 100%;
              height: 1px;
              width: 100vw;
              background-color: #b9b9b9;
              content: '';
              display: block;
            }
          }
        }
      }

      .tabs_list-content {
        padding-top: 20px;

        .player-area {
          //max-width: 1200px;
          height: calc(100vh - 240px);

          .video-js {
            padding-top: 0px;
            height: 100%;
            text-align: center;

            video {
              height: 100%;
              width: auto;
              position: static;

              &:focus {
                outline: none;
              }
            }
          }
        }

        .leaflet-map-container {
          max-width: 1200px;
        }
      }
    }
  }
}

footer {
  background-color: $blue;
  padding-top: 15px;
  padding-bottom: 15px;

  p {
    color: white;
    font-size: 13px;
    text-align: center;
  }
}

.main-wrapper {
  width: 100vw;
  height: 100vh;

  .form-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $images-grey;

    .form-wrapper {
      max-width: 560px;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #D1DDEB;
      background-color: white;

      .form-title {
        display: flex;
        align-items: center;
        background-color: #F5F5F5;
        padding: 10px 15px;
        border-bottom: 1px solid #DCDCDC;

        img {
          max-width: 60px;
          margin-right: 7px;
        }

        p {
          font-weight: 600;
          color: #2C2C2C;
          font-size: 18px;
          line-height: 1.25;
        }
      }

      .form {
        padding: 10px 15px;

        .form-item {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          flex-wrap: wrap;

          &.top {
            margin-bottom: 11px;
          }

          &.checkbox {
            margin-bottom: 11px;
          }

          &.error {
            input {
              border: 1px solid red;
              box-shadow: inset 0 1px 1px rgba(255, 0, 0, 0.19);
            }

            p {
              &.warning-text {
                display: block;
              }
            }
          }

          input {
            height: 38px;
            font-size: 14px;
            margin-bottom: 0;
            border-radius: 5px;

            &[type="checkbox"] {
              margin-right: 7px;
              height: 13px;
            }

            &.submit {
              margin-bottom: 0;
              background-color: $blue;
              box-shadow: none;
              border: 0;
              width: 100%;
              color: white;
              height: 44px;
              font-size: 16px;
            }
          }

          p {
            margin-bottom: 0;
            font-size: 13px;

            &.warning-text {
              color: red;
              display: none;
            }
          }
        }
      }
    }
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  min-width: 1200px;
  min-height: 687px;
  max-height: 95vh;

  .modal-header {
    background-color: $blue;
    display: flex;
    padding: 10px;
    color: $white;

    p {
      font-size: 16px;
      color: white;
      margin-bottom: 0;
    }

    .modal-close {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: auto;
    }
  }

  .modal-content {
    border: 2px solid $blue;
    overflow-y: scroll;
    max-height: calc(95vh - 20px);
    min-height: 606px;
  }
}

form.eventForm {
  //width: 800px;
  width: 100%;

  .border-wrapper {
    border-left: 1px solid $blue;
    border-bottom: 1px solid $blue;
    display: flex;

    .modal-form {
      flex: 65%;
      max-width: 645px;

      .form-group {
        padding: 7px;
        border: 1px solid $lgrey;
        border-top: 0;
        margin: 15px 10px 15px;

        .title {
          position: relative;
          display: inline-block;
          display: flex;
          margin-top: -18px;
          padding-left: 7px;

          .title-text {
            flex: 0 0 auto;
            max-width: 100%;
            margin-right: 5px;
            position: relative;
            margin-bottom: 14px;

            &:before {
              width: 8px;
              height: 1px;
              position: absolute;
              display: block;
              content: '';
              top: 11px;
              left: -14px;
              background-color: $lgrey;
            }

            p {
              color: $grey;
              font-weight: $mainfw;
            }
          }

          .title-line {
            position: relative;
            padding-right: 0;
            flex: 1 1 0;

            &:after {
              width: 100%;
              height: 1px;
              position: absolute;
              display: block;
              content: '';
              top: 11px;
              right: -7px;
              background-color: $lgrey;
            }
          }
        }

        .form-input {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          &.textarea {
            align-items: flex-start;
          }

          .title-group {
            width: 165px;
            max-width: 165px;
            flex: 1;

            p {
              color: $grey;
              margin-bottom: 0;
              font-size: 13px;
              font-weight: $mainfw;
            }
          }

          .input {
            flex: 1;

            input, textarea {
              height: 30px;
              font-size: 13px;
              padding: 5px 10px 4px;
              margin-bottom: 0;
              box-shadow: none;
              border: 1px solid $lgrey;
              color: $dgrey;
              font-weight: $mainfw;

              &[readonly] {
                background-color: transparent;
              }

              &:focus {
                border: 1px solid $blue;
              }

              @media (max-width: 1410px){
                height: 25px;
              }
            }

            textarea {
              min-height: 60px;
            }
          }
        }
      }

    }

    .modal-imgs {
      padding-top: 10px;
      flex: 35%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      img {
        width: auto;
        //flex: 47%;
        height: auto;
        margin-right: 5px;
        max-width: 255px;
        object-fit: contain;
      }
    }
  }

  .modal-btns {
    .margin-wrapper {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;

      button {
        width: 140px;
        padding-top: 7px;
        padding-bottom: 7px;
        text-align: center;
        background-color: $blue;
        color: white;
        font-size: 13px;
      }
    }
  }
}

.react-bootstrap-table,
.react-grid-Container {
  thead,
  .react-grid-HeaderRow {
    height: 32px;
    overflow-y: hidden;

    th,
    .react-grid-HeaderCell {
      border: 1px solid $lgrey;
      border-left: 0;
      color: $dgrey;
      font-size: 13px;
      font-weight: $mainfw;
      padding: 5px 10px;
      position: relative;
      height: 32px;

      & > div,
      & > label {
        margin-bottom: -6px;
      }

      input, select {
        position: relative;
        width: calc(100% + 20px);
        font-size: 13px;
        color: $dgrey;
        margin: 6px -10px 0;
        box-shadow: none;
        height: 30px;
      }

      &.table-date {
        > div {
          display: flex;

          > span {
            display: none;
          }
        }
      }
    }
  }

  tbody, .react-grid-Viewport {
    tr, .react-grid-Row {
      border-bottom: 1px solid $llgrey;

      &:nth-child(2n) {
        background-color: #f6f6f6;

        &.selection-row {
          background-color: $light-yellow;
        }
      }

      &:hover {
        background-color: $light-blue-grey;
      }
    }

    .rdg-cell-action-last {
      float: left;
      color: black;
      font-size: 13px;
      padding: 7px 10px 6px;
      font-weight: $mainfw;
      white-space: nowrap;
      overflow: hidden;

      button {
        padding: 0;
      }
    }

    .table-cell, td, .react-grid-Cell__value, button {
      color: black;
      font-size: 13px;
      padding: 7px 10px 6px;
      font-weight: $mainfw;
      white-space: nowrap;
      overflow: hidden;

      &.react-bootstrap-table-editing-cell {
        overflow: inherit;
      }

      .rdg-drag-row-handle {
        margin-top: 4px !important;
        max-height: 20px;
        margin-left: -3px;
        max-width: 4px;
      }
    }

    .selection-row {
      background-color: $light-yellow;

      &:hover {
        background-color: $light-blue-grey;
      }
    }
  }
}

.tableCheckbox, .react-bootstrap-table-editing-cell input[type="checkbox"] {
  margin: 0 0 0 7px !important;
  padding: 0 !important;
}

.react-grid-Grid {
  border-top: 0;

  .react-grid-Header {
    box-shadow: none;
    background-color: #f8f8f8;
    max-height: 32px;

    .react-grid-HeaderCell {
      bottom: 0;
      padding: 7px 10px;

      & > .react-grid-checkbox-container {
        & > .react-grid-checkbox-label {
          margin-top: 3px;
        }
      }
    }
  }

  .react-grid-Cell {
    border-right: 0;
    border-bottom: 1px solid $llgrey;
    padding-left: 0;
    padding-right: 0;
  }

  .rdg-last--frozen {
    box-shadow: none !important;
  }

  .react-grid-HeaderCell--frozen {
    &:last-of-type {
      box-shadow: none;
    }
  }

  .checkbox-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-grid-Viewport {
    top: 32px !important;
    inset: 32px 0 0 !important;
  }
}

.react-datepicker__navigation--previous {
  border-right-color: #7b7b7b;

  &:hover {
    border-right-color: #a2a2a2;
  }
}

.react-datepicker__navigation--next {
  border-left-color: #7b7b7b;

  &:hover {
    border-left-color: #a2a2a2;
  }
}

.paginationContainer {
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;

  > div {
    padding-bottom: 30px;

    &.item-on-page {
      margin-right: 100px;
    }

    > p {
      font-size: 13px;
      font-weight: $mainfw;
      order: 2;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  .pagination {
    margin-bottom: 0px;

    .page-item {
      margin-right: 5px;
      font-size: 13px;
      font-weight: $mainfw;

      a {
        padding: 6px 12px;

        &:hover {
          color: #0a0a0a;
        }
      }

      &.active {
        background-color: $blue;

        a {
          color: white;
        }
      }
    }
  }

  .dropdown {
    font-size: 13px;
    font-weight: $mainfw;
    position: relative;

    .dropdown-toggle {
      border: 1px solid $lgrey;
      padding: 7px 35px 7px 10px;
      position: relative;
      outline: none;
      font-weight: $mainfw;

      &:after {
        pointer-events: none;
        position: absolute;
        content: "\f0dd";
        display: block;
        top: 2px;
        right: 11px;
        font-family: FontAwesome;
        font-size: 16px;
      }
    }

    .dropdown-menu {
      display: none;

      &.open {
        display: block;
        position: absolute;
        background-color: white;
        list-style-type: none;
        width: 100%;
        margin-left: 0;
        bottom: 28px;
        border: 1px solid #d0d0d0;
        border-bottom: none;

        li {
          border-bottom: 1px solid #d0d0d0;

          a {
            color: black;
            display: inline-block;
            padding: 4px 10px;
            width: 100%;
          }

          &:hover {
            background-color: $blue;

            a {
              color: white;
            }
          }
        }
      }
    }

    &.open {
      .dropdown-toggle {
        &:after {
          top: 10px;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.report-form-wrapper {
  padding-left: 28px;

  .title-form {
    font-size: 15px;
    font-weight: 700;
    font-family: $open_sans;
    margin-left: 150px;
    padding: 40px 0;
  }

  .form-group {
    max-width: 700px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .title {
      min-width: 135px;

      p {
        font-size: 13px;
        font-weight: $mainfw;
        font-family: $open_sans;
      }
    }

    .input {
      display: flex;
      min-width: 280px;
      width: 100%;

      &.dates {
        .react-datepicker__input-container {
          position: relative;

          &:after {
            pointer-events: none;
            position: absolute;
            content: "\f073";
            display: block;
            top: 4px;
            right: 11px;
            font-family: FontAwesome;
            font-size: 16px;
            color: #919191;
          }
        }
      }

      .input-wrapper {
        min-width: 280px;
        margin-right: 5px;

        .react-datepicker-wrapper {
          width: 100%;
        }
      }

      input, select {
        height: 32px;
        margin: 0;
        font-size: 13px;
        font-weight: $mainfw;
        font-family: $open_sans;
        border: 1px solid $lgrey;

        &:focus {
          border-color: #3892d4;
          box-shadow: none;
        }
      }
    }
  }

  .btn-wrapper {
    padding-top: 13px;
    padding-left: 150px;

    button {
      color: #f0f0f0;
      background-color: $btn-blue;
      border: 1px solid #5897ce;
      padding: 9px 13px;
      font-size: 12px;
      font-family: $open_sans;
    }
  }
}


form.disabled {
  pointer-events: none;
  .select-list div {
    background-color: #e6e6e6 !important;
  }
}

.btn-wrapper {
  padding: 6px 9px;
  border-top: 1px solid #d0d0d0;

  .table-control-btn {
    background-color: #f6f6f6;
    font-size: 12px;
    border: 1px solid #e4e4e4;
    padding: 7px 11px;
    margin-right: 17px;

    &:hover {
      background-color: #4196d4;
      border-color: #4196d4;
      color: white;
    }

    &:focus {
      box-shadow: $blue 0 1px 0 0 inset, $blue 0 -1px 0 0 inset, $blue -1px 0 0 0 inset, $blue 1px 0 0 0 inset;
      outline: none;
    }

    &.btn-create {
      margin-right: 8px;
    }

    &[disabled] {
      opacity: 0.5;

      &:hover {
        background-color: #f6f6f6;
        border: 1px solid #e4e4e4;
        color: #0a0a0a80;
      }
    }

    svg {
      margin-right: 7px;
    }
  }

  &.bottom-btn {
    button {
      background-color: $blue;
      border: 1px solid #5897ce;
      padding: 9px 13px;
      color: #f0f0f0;
      margin-right: 5px;
      font-size: 13px;
      font-weight: $mainfw;
      font-family: $open_sans;
    }
  }
}

.edit-wrapper {
  position: relative;
  border: 1px solid #d0d0d0;
  width: calc(100% + 20px);
  margin-left: -10px;
  height: calc(100% + 14px);
  margin-top: -7px;
  margin-bottom: -7px;
  border-top: 0;

  .double-field-wrapper {
    display: flex;
  }

  .field-wrapper {
    background-color: #e5edf4;
    padding: 4px;

    input, select {
      margin-bottom: 0;
      height: 24px;
      font-size: 13px;
      font-weight: $mainfw;
      font-family: $open_sans;
      border: 1px solid $lgrey;
      padding-left: 4px;

      &.required-field {
        border-color: #cf4c35;
      }
    }

    &.field-wrapper-checkbox {
      padding: 3px 0 1px 9px;
    }
  }

  .buttons {
    user-select: none;
    background-color: #e5edf4;
    position: absolute;
    right: -1px;
    padding: 4px 0 4px 4px;
    border: 1px solid #d0d0d0;
    border-top: none;

    button {
      background-color: $blue;
      border: 1px solid #5897ce;
      padding: 9px 13px;
      color: #f0f0f0;
      margin-right: 5px;
      font-size: 13px;
      font-weight: $mainfw;
      font-family: $open_sans;

      &:focus {
        box-shadow: #ced8e1 0 1px 0 0 inset, #ced8e1 0 -1px 0 0 inset, #ced8e1 -1px 0 0 0 inset, #ced8e1 1px 0 0 0 inset;
        outline: none;
        background-color: $btn-blue;
        border-color: #518bbd;
      }

      &:hover {
        background-color: $btn-blue;
        border-color: #518bbd;
      }
    }
  }
}

.modal {
  min-width: 996px;
  min-height: auto;

  .modal-header {
    padding: 5px 0 5px 16px;
    justify-content: space-between;
    align-items: center;

    .btn-wrapper {
      border-top: 0;

      button {
        color: white;
        border: 1px solid #5897ce;

        font: $mainfw 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
        font-size: 12px;
        padding: 7px 11px;

        &:first-child {
          margin-right: 5px;
        }

        .button-text {
          margin-left: 5px;
        }
      }
    }
  }
}

.event-notification-modal {
  .modal {
    bottom: 20px;
    right: 10px;
    width: 500px;
    min-width: auto;
    position: absolute;
    border: 3px solid $blue;
  }

  .modal-content {
    padding: 15px;
  }
}

.user-modal {
  .modal-content {
    border-bottom: 0;
    max-height: none;
    height: 100%;
    overflow: auto;

    .form-group {
      padding: 7px;
      border: 1px solid $grey;
      border-top: 0;
      margin: 15px 10px 30px;

      .title {
        position: relative;
        display: inline-block;
        display: flex;
        margin-top: -18px;
        padding-left: 7px;

        .title-text {
          flex: 0 0 auto;
          max-width: 100%;
          margin-right: 5px;
          position: relative;
          margin-bottom: 21px;

          &:before {
            width: 8px;
            height: 1px;
            position: absolute;
            display: block;
            content: '';
            top: 11px;
            left: -14px;
            background-color: $grey;
          }

          p {
            color: $grey;
            font: $mainfw 16px/20px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
          }
        }

        .title-line {
          position: relative;
          padding-right: 0;
          flex: 1 1 0;

          &:after {
            width: 100%;
            height: 1px;
            position: absolute;
            display: block;
            content: '';
            top: 11px;
            right: -7px;
            background-color: $grey;
          }
        }
      }

      .pass-wrapper {
        display: flex;

        .pass {
          max-width: 487px;
          flex: 487px;
        }

        .passwordRepeat {
          flex: 1;
        }
      }

      .select-list {
        & > div {
          div[class*="control"] {
            height: 30px !important;
            max-height: 30px !important;
            min-height: 30px !important;
            font-size: 13px;
            //padding: 5px 10px 4px;
            margin-bottom: 0;
            box-shadow: none;
            border: 1px solid $lgrey;
            color: $dgrey;
            font-weight: $mainfw !important;
            border-radius: 0 !important;

            &[readonly] {
              background-color: transparent;
            }

            &:focus {
              border: 1px solid $blue;
            }

            &::placeholder {
              color: $dgrey;
            }

            div[class*="control"] {
              padding-top: 0;
            }

            div[class*="IndicatorsContainer"] {
              height: 30px;
            }

            div[class*="ValueContainer"] {
              max-height: 30px;
              padding-top: 0;
              align-items: flex-start;

              div[class*="placeholder"] {
                top: 52%;
              }

              div[class*="Input"] {
                margin-top: 0;
                padding-bottom: 0;
                padding-top: 0;
              }

              div[class*="multiValue"] {
                margin-top: 2px;
              }
            }

            div[class*="menu"] {
              margin-top: 0;
            }
          }
        }
      }

      &.rols-form {
        .input {
          height: 30px !important;
          max-height: 30px !important;
          min-height: 30px !important;
          font-size: 13px;
          //padding: 5px 10px 4px;
          margin-bottom: 0;
          box-shadow: none;
          //border: 1px solid $lgrey;
          color: $dgrey;
          font-weight: $mainfw;
          border-radius: 0 !important;

          &[readonly] {
            background-color: transparent;
          }

          &:focus {
            border: 1px solid $blue;
          }

          &::placeholder {
            color: $dgrey;
          }
        }
      }

      .form-input {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        &.textarea {
          align-items: flex-start;
        }

        &.objects {
          align-items: flex-start;

          .title-group {
            margin-top: 4px;
          }
        }

        .title-group {
          width: 161px;
          max-width: 161px;
          flex: 1;
          margin-right: 8px;

          .custom-control {
            display: flex;

            .custom-control-input {
              margin: 0;
            }
          }

          p {
            color: $black;
            margin-bottom: 0;
            text-align: right;
            font: $mainfw 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
          }
        }

        .input {
          flex: 1;

          input, textarea {
            height: 30px;
            font-size: 13px;
            padding: 5px 10px 4px;
            margin-bottom: 0;
            box-shadow: none;
            border: 1px solid $lgrey;
            color: $dgrey;
            font-weight: $mainfw;

            &[readonly] {
              background-color: transparent;
            }

            &:focus {
              border: 1px solid $blue;
            }

            &::placeholder {
              color: $dgrey;
            }

            &.is-invalid {
              border-color: #cf4c35;
            }

            @media (max-width: 1410px) {
              height: 25px;
            }
          }

          textarea {
            min-height: 60px;
          }

          .invalid-feedback {
            display: none;
          }

          .full-name {
            display: flex;
          }

          &.object-list {
            .row {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0;

              .select-list {
                flex: 1;
              }

              .custom-control {
                padding-left: 10px;
              }
            }
          }
        }

        .add_object {
          button {
            font: $mainfw 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
            color: #0a0a0a;
            background-color: #f6f6f6;
            font-size: 12px;
            border: 1px solid #e4e4e4;
            padding: 7px 11px;

            &:hover {
              background-color: #4196d4;
              border-color: #4196d4;
              color: white;
            }

            &:focus {
              box-shadow: $blue 0 1px 0 0 inset, $blue 0 -1px 0 0 inset, $blue -1px 0 0 0 inset, $blue 1px 0 0 0 inset;
              outline: none;
            }

            svg {
              margin-right: 7px;
            }
          }
        }
      }

      &.long-title {
        .title-group {
          max-width: 243px;
          width: 243px;
        }
      }

      &.statistic {
        input {
          background: rgb(243, 242, 243) none repeat scroll 0% 0%;
        }
      }
    }

    .submit-wrapper {
      border-top: 1px solid $lgrey;
      padding: 10px 10px 5px;
      text-align: right;

      button {
        width: 140px;
        padding-top: 10px;
        padding-bottom: 9px;
        text-align: center;
        background-color: $blue;
        color: white;
        font-size: 13px;
      }
    }
  }
}

.workwear-modal {
  .modal {
    min-width: 509px!important;
    height: 424px;
    >.modal-content {
      max-height: none;
      overflow: auto;
      min-height: 370px;

      .form-group {
        padding: 7px;
        border: 1px solid $grey;
        border-top: 0;
        margin: 15px 10px 10px;

        .title {
          position: relative;
          display: flex;
          margin-top: -18px;
          padding-left: 7px;

          .title-text {
            flex: 0 0 auto;
            max-width: 100%;
            margin-right: 5px;
            position: relative;
            margin-bottom: 21px;

            &:before {
              width: 8px;
              height: 1px;
              position: absolute;
              display: block;
              content: '';
              top: 11px;
              left: -14px;
              background-color: $grey;
            }

            p {
              color: $grey;
              font: $mainfw 16px/20px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
            }
          }

          .title-line {
            position: relative;
            padding-right: 0;
            flex: 1 1 0;

            &:after {
              width: 100%;
              height: 1px;
              position: absolute;
              display: block;
              content: '';
              top: 11px;
              right: -7px;
              background-color: $grey;
            }
          }
        }

        .pass-wrapper {
          display: flex;

          .pass {
            max-width: 487px;
            flex: 487px;
          }

          .passwordRepeat {
            flex: 1;
          }
        }

        .select-list {
          & > div {
            div[class*="control"] {
              height: 30px !important;
              max-height: 30px !important;
              min-height: 30px !important;
              font-size: 13px;
              //padding: 5px 10px 4px;
              margin-bottom: 0;
              box-shadow: none;
              border: 1px solid $lgrey;
              color: $dgrey;
              font-weight: $mainfw !important;
              border-radius: 0 !important;

              &[readonly] {
                background-color: transparent;
              }

              &:focus {
                border: 1px solid $blue;
              }

              &::placeholder {
                color: $dgrey;
              }

              div[class*="control"] {
                padding-top: 0;
              }

              div[class*="IndicatorsContainer"] {
                height: 30px;
                overflow: hidden;
              }

              div[class*="ValueContainer"] {
                max-height: 30px;
                padding-top: 0;
                align-items: flex-start;

                div[class*="placeholder"] {
                  top: 52%;
                }

                div[class*="Input"] {
                  margin-top: 0;
                  padding-bottom: 0;
                  padding-top: 0;
                }

                div[class*="multiValue"] {
                  margin-top: 2px;
                }
              }

              div[class*="menu"] {
                margin-top: 0;
              }
            }
          }
        }

        &.rols-form {
          .input {
            height: 30px !important;
            max-height: 30px !important;
            min-height: 30px !important;
            font-size: 13px;
            //padding: 5px 10px 4px;
            margin-bottom: 0;
            box-shadow: none;
            //border: 1px solid $lgrey;
            color: $dgrey;
            font-weight: $mainfw;
            border-radius: 0 !important;

            &[readonly] {
              background-color: transparent;
            }

            &:focus {
              border: 1px solid $blue;
            }

            &::placeholder {
              color: $dgrey;
            }
          }
        }

        .form-input {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          &.textarea {
            align-items: flex-start;
          }

          &.objects {
            align-items: flex-start;

            .title-group {
              margin-top: 4px;
            }
          }

          .title-group {
            width: 161px;
            max-width: 161px;
            flex: 1;
            margin-right: 8px;

            .custom-control {
              display: flex;

              .custom-control-input {
                margin: 0;
              }
            }

            p {
              color: $black;
              margin-bottom: 0;
              text-align: right;
              font: $mainfw 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
            }
          }

          .input {
            flex: 1;

            input, textarea {
              height: 30px;
              font-size: 13px;
              padding: 5px 10px 4px;
              margin-bottom: 0;
              box-shadow: none;
              border: 1px solid $lgrey;
              color: $dgrey;
              font-weight: $mainfw;

              &[readonly] {
                background-color: transparent;
              }

              &:focus {
                border: 1px solid $blue;
              }

              &::placeholder {
                color: $dgrey;
              }

              &.is-invalid {
                border-color: #cf4c35;
              }
            }

            textarea {
              min-height: 60px;
            }

            .invalid-feedback {
              display: none;
            }

            .full-name {
              display: flex;
            }

            &.object-list {
              .row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;

                .select-list {
                  flex: 1;
                }

                .custom-control {
                  padding-left: 10px;
                }
              }
            }
          }

          .add_object {
            button {
              font: $mainfw 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
              color: #0a0a0a;
              background-color: #f6f6f6;
              font-size: 12px;
              border: 1px solid #e4e4e4;
              padding: 7px 11px;

              &:hover {
                background-color: #4196d4;
                border-color: #4196d4;
                color: white;
              }

              &:focus {
                box-shadow: $blue 0 1px 0 0 inset, $blue 0 -1px 0 0 inset, $blue -1px 0 0 0 inset, $blue 1px 0 0 0 inset;
                outline: none;
              }

              svg {
                margin-right: 7px;
              }
            }
          }
        }

        &.long-title {
          .title-group {
            max-width: 243px;
            width: 243px;
          }
        }

        &.statistic {
          input {
            background: rgb(243, 242, 243) none repeat scroll 0% 0%;
          }
        }
      }
    }
  }

  .modal-content {

    .submit-wrapper {
      border-bottom: 1px solid $lgrey;
      padding: 10px 10px 30px;
      text-align: center;

      button {
        //width: 140px;
        padding: 10px 10px 10px 10px;
        margin-right: 10px;
        text-align: center;
        background-color: $blue;
        color: white;
        font-size: 13px;
      }
    }
  }
}

.error-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .fa-w-18 {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 20px;
  }
}

.dummy-column {
  background: $header-grey;
  border: 1px solid $llgrey;
}

.task-indicator {
  position: fixed;
  top: 50vh;
  left: 50vw;
  height: 200px;
  width: 200px;
  border: solid 1px lightgray;
  border-radius: 10px;
  background: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task-indicator__spinner-wrapper {
  height: 70%;
  display: flex;
  align-items: center;
}

.task-indicator__spinner-wrapper .vz-spinner-container {
  padding: 0;
}

.vz-spinner-container{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.vz-spinner {
  width: 100px
}

#git-graph svg circle {
  cursor: pointer;
}

#git-graph svg g > text {
  cursor: pointer;
}

.react-tabs {
  margin-top: 20px;
  .react-tabs__tab-list {
    border-bottom: 1px solid #b9b9b9;
  }
  .react-tabs__tab--selected {
      border-color: #b9b9b9;
  }
  .react-tabs__tab {
    padding: 8px 15px;
    transition: all .3s ease;
    position: relative;
    font-size: 14px;
    color: #545252;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

.player-area {
  max-width: 1280px;
}


.camera_wrapper_buttons{
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;

  .error-text {
    color: red;
    margin-bottom: 9px;
    
  }
  div {
    border-top: none !important;
  }

}

.btn-disable-confirm {
  background-color: #ababab !important;
}