@import "faq";

.zone-objects {
  display: flex;
  width: 100%;
  height: 100%;
  &_list {
    width: 300px;
    border: 1px solid #000000;
    padding: 10px;
    display: flex;
    flex-direction: column;
    &-item {
      padding-bottom: 10px;
      &_title {
        font-weight: bold;
      }
      &_menuItems {
        list-style: none;
      }
      &_menuItem {
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
  &_wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    &-title {
      font-weight: 500;
      color: #3d5170;
      font-size: 20px;
      padding-bottom: 10px;
    }
    &-selectors {
      display: flex;
      justify-content: end;
      &__selector {
        display: flex;
        align-items: center;
        &--name {
          display: flex;
          width: 160px;
          justify-content: center;
        }
        &--field {
          display: flex;
          width: 200px;
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: $open_sans;
          font-weight: $mainfw;
        }
      }
    }
    &-editor {
      border: 1px solid #000000;
      height: 100%;
    }
  }
}

.zoneeditform {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-trashbutton {
    width: 40px;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #5A6169;
    color: red;
  }
  &-inputs {
    display: flex;
    &--control {
      width: 100%;
      display: flex;
      padding-right: 10px;
      &__right {
        justify-content: end;
      }
      &__label {
        padding-right: 10px;
        font-weight: 500;
        font-size: medium;
        margin-top: 5px;
      }
      input {
        width: 600px;
      }
      select {
        width: 200px;
      }
    }
  }

  &-submitwrapper {
    display: flex;
    justify-content: end;
    padding-top: 20px;
    &--button {
      padding-left: 20px;
      &__submit {
        padding: 20px;
        background: #5fa2dd;
        color: #fff;
      }
      &__cancel {
        padding: 20px;
        font-weight: 500;
      }
    }
  }
}

.zonedelete-modal {
  width: 500px;
  background: #fff;
  .modal-header {
    padding: 5px 0 5px 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #5fa2dd;
    display: flex;
    color: #fefefe;
    .btn-wrapper {
      color: white;
      border: 1px solid #5897ce;
      font: 300 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
      font-size: 12px;
      padding: 7px 11px;
    }
  }
  &-modalwrapper {
    display: flex;
    flex-direction: column;
    &-dialog {
      display: flex;
      font-weight: 500;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &-submitwrapper {
      display: flex;
      justify-content: center;
      padding: 20px;
      width: 100%;
      &--button {
        padding-left: 20px;
        &__submit {
          padding: 10px;
          background: #ff0000;
          color: #fff;
        }
        &__cancel {
          padding: 10px;
          font-weight: 500;
        }
      }
    }
  }
}
