@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Thin'),
  local('../assets/fonts/Roboto-Thin'),
  url('../assets/fonts/Robotothin.woff2') format('woff2'),
  url('../assets/fonts/Robotothin.woff') format('woff'),
  url('../assets/fonts/Robotothin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Thin Italic'),
  local('../assets/fonts/Roboto-ThinItalic'),
  url('../assets/fonts/Robotothinitalic.woff2') format('woff2'),
  url('../assets/fonts/Robotothinitalic.woff') format('woff'),
  url('../assets/fonts/Robotothinitalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Light'),
  local('../assets/fonts/Roboto-Light'),
  url('../assets/fonts/Robotolight.woff2') format('woff2'),
  url('../assets/fonts/Robotolight.woff') format('woff'),
  url('../assets/fonts/Robotolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Light Italic'),
  local('../assets/fonts/Roboto-LightItalic'),
  url('../assets/fonts/Robotolightitalic.woff2') format('woff2'),
  url('../assets/fonts/Robotolightitalic.woff') format('woff'),
  url('../assets/fonts/Robotolightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto'),
  local('../assets/fonts/Roboto-Regular'),
  url('../assets/fonts/Roboto.woff2') format('woff2'),
  url('../assets/fonts/Roboto.woff') format('woff'),
  url('../assets/fonts/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Italic'),
  local('../assets/fonts/Roboto-Italic'),
  url('../assets/fonts/Robotoitalic.woff2') format('woff2'),
  url('../assets/fonts/Robotoitalic.woff') format('woff'),
  url('../assets/fonts/Robotoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Medium'),
  local('../assets/fonts/Roboto-Medium'),
  url('../assets/fonts/Robotomedium.woff2') format('woff2'),
  url('../assets/fonts/Robotomedium.woff') format('woff'),
  url('../assets/fonts/Robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Medium Italic'),
  local('../fonts/Roboto-MediumItalic'),
  url('../assets/fonts/Robotomediumitalic.woff2') format('woff2'),
  url('../assets/fonts/Robotomediumitalic.woff') format('woff'),
  url('../assets/fonts/Robotomediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Bold'),
  local('../assets/fonts/Roboto-Bold'),
  url('../assets/fonts/Robotobold.woff2') format('woff2'),
  url('../assets/fonts/Robotobold.woff') format('woff'),
  url('../assets/fonts/Robotobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Bold Italic'),
  local('../assets/fonts/Roboto-BoldItalic'),
  url('../assets/fonts/Robotobolditalic.woff2') format('woff2'),
  url('../assets/fonts/Robotobolditalic.woff') format('woff'),
  url('../assets/fonts/Robotobolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Black'),
  local('../assets/fonts/Roboto-Black'),
  url('../assets/fonts/Robotoblack.woff2') format('woff2'),
  url('../assets/fonts/Robotoblack.woff') format('woff'),
  url('../assets/fonts/Robotoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('../assets/fonts/Roboto Black Italic'),
  local('../assets/fonts/Roboto-BlackItalic'),
  url('../assets/fonts/Robotoblackitalic.woff2') format('woff2'),
  url('../assets/fonts/Robotoblackitalic.woff') format('woff'),
  url('../assets/fonts/Robotoblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Light'),
  local('../assets/fonts/OpenSans-Light'),
  url('../assets/fonts/opensanslight.woff2') format('woff2'),
  url('../assets/fonts/opensanslight.woff') format('woff'),
  url('../assets/fonts/opensanslight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Light Italic'),
  local('../assets/fonts/OpenSansLight-Italic'),
  url('../assets/fonts/opensanslightitalic.woff2') format('woff2'),
  url('../assets/fonts/opensanslightitalic.woff') format('woff'),
  url('../assets/fonts/opensanslightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans'),
  local('../assets/fonts/OpenSans'),
  url('../assets/fonts/opensans.woff2') format('woff2'),
  url('../assets/fonts/opensans.woff') format('woff'),
  url('../assets/fonts/opensans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Italic'),
  local('../assets/fonts/OpenSans-Italic'),
  url('../assets/fonts/opensansitalic.woff2') format('woff2'),
  url('../assets/fonts/opensansitalic.woff') format('woff'),
  url('../assets/fonts/opensansitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Semibold'),
  local('../assets/fonts/OpenSans-Semibold'),
  url('../assets/fonts/opensanssemibold.woff2') format('woff2'),
  url('../assets/fonts/opensanssemibold.woff') format('woff'),
  url('../assets/fonts/opensanssemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Semibold Italic'),
  local('../assets/fonts/OpenSans-SemiboldItalic'),
  url('../assets/fonts/opensanssemibolditalic.woff2') format('woff2'),
  url('../assets/fonts/opensanssemibolditalic.woff') format('woff'),
  url('../assets/fonts/opensanssemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Bold'),
  local('../assets/fonts/OpenSans-Bold'),
  url('../assets/fonts/opensansbold.woff2') format('woff2'),
  url('../assets/fonts/opensansbold.woff') format('woff'),
  url('../assets/fonts/opensansbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Bold Italic'),
  local('../assets/fonts/OpenSans-BoldItalic'),
  url('../assets/fonts/opensansbolditalic.woff2') format('woff2'),
  url('../assets/fonts/opensansbolditalic.woff') format('woff'),
  url('../assets/fonts/opensansbolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Extrabold'),
  local('../assets/fonts/OpenSans-Extrabold'),
  url('../assets/fonts/opensansextrabold.woff2') format('woff2'),
  url('../assets/fonts/opensansextrabold.woff') format('woff'),
  url('../assets/fonts/opensansextrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: local('../assets/fonts/Open Sans Extrabold Italic'),
  local('../assets/fonts/OpenSans-ExtraboldItalic'),
  url('../assets/fonts/opensansextrabolditalic.woff2') format('woff2'),
  url('../assets/fonts/opensansextrabolditalic.woff') format('woff'),
  url('../assets/fonts/opensansextrabolditalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}