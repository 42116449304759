.images-list {
  margin-bottom: 20px;
  .images_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px 10px;
    margin-bottom: 5px;
    background-color: $header-grey;
    border-bottom: 1px solid $lgrey;
    .images_header_item {
      font-size: 13px;
      display: flex;
      color: $text-grey;
      align-items: center;
      > button {
        padding: 7px 7px 8px 30px;
        background-color: $images-bgrey;
        border: 1px solid #e4e4e4;
        position: relative;
        color: inherit;
        transition: $animation;
        &:hover {
          background-color: #4196d4;
          border: 1px solid #4196d4;
          color: white;
        }
      }
      p {
        margin-right: 9px;
        font-size: inherit;
        white-space: nowrap;
      }
      select, input {
        font-size: inherit;
        color: inherit;
        padding: 5px 22px 4px 10px;
        height: 30px;
        margin-bottom: 0;
        max-width: 290px;
        margin-right: 7px;
        border: 1px solid $lgrey;
        box-shadow: none;
        &:focus {
          border: 1px solid #3892d4;
        }
      }
      &.images_clean {
        flex: 1 0 116px;
        max-width: 116px;
        a, button {
          svg{
            position: absolute;
            content: "\f0e2";
            display: block;
            top: 6px;
            left: 6px;
            font-family: FontAwesome;
            width: 13px;
            height: 15px;
          }
        }
      }
      &.images_filter {
        flex: 2 1 385px;
        max-width: 385px;
      }
      &.images_room {
        flex: 2 1 295px;
        max-width: 295px;
      }
      &.images_dates {
        flex: 2 1 240px;
        max-width: 240px;
        .input-wrapper {
          margin-right: 8px;
          flex: 50%;
          .react-datepicker-wrapper {
            position: relative;
            &:after {
              pointer-events: none;
              position: absolute;
              content: "\f073";
              display: block;
              top: 4px;
              right: 11px;
              font-family: FontAwesome;
              font-size: 16px;
          }
          }
        }
      }
      &.images_violation {
        flex: 2 1 240px;
        max-width: 240px;
      }
      &.images_search {
        flex: 1 0 100px;
        max-width: 100px;
        a, button {
          svg {
            position: absolute;
            content: "\f002";
            display: block;
            top: 7px;
            left: 9px;
            font-family: FontAwesome;
            width: 13px;
            height: 15px;
          }
        }
      }
      &.images_limit {
        flex: 1 0 123px;
        max-width: 123px;
      }
    }
  }
  .images-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .images-item {
      flex: 10%;
      max-width: 178px;
      min-width: 178px;
      border: 1px solid $images-grey;
      margin: 5px;
      transition: $animation;
      &.emploee {
        max-width: 160px;
        min-width: 160px;
      }
      &:hover {
        border: 1px solid $images-hover;
        cursor: pointer;
      }
      .img-wrapper {
        img {
          height: auto;
          max-height: 356px;
          object-fit: cover;
          width: 100%;
          object-position: top;
        }
      }
      .text-wrapper {
        padding: 15px 4px;
        text-align: center;
        color: $dgrey;
        font-size: 13px;
      }
    }
  }
}
