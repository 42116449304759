iframe {
    margin: 0 auto;
    display: block;
    /*width: 100%;*/
    /*height: 100%;*/
    /*margin-left: auto;   !* Automatic margin from left *!*/
    /*margin-right: auto;*/
    /*max-width: 100% !important;*/
    /*width: auto !important;*/
    /*height: auto !important;*/
}
.vz-wrapper {
    position: relative;
}

.vz-wrapper img {
    position: absolute;
    right: 30px;
    bottom: 20px;
    z-index: 99999;
    width: 35px;
}

/*.fullscreen {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    width: 100%; height: 100%;*/
/*    background-color: #ccc;*/
/*}*/

.fullscreen {
    position: fixed;
    display: block;
    display: grid;
    z-index: 9999999;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: all 400ms cubic-bezier(0.6,-0.6,0.125,1.65);
    transition: all 400ms cubic-bezier(0.6,-0.6,0.125,1.65);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    border-radius: 0;
    opacity: 1;
    background-color: #000;
}

iframe {
    width: 100%;
    height:100%;
    border: 0px;
}

